<template>
	<b-modal id="endorse-repaired-batch" :title="title" size="lg" ok-title="Save" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row>
					<b-col sm="6">
						<b-form-group>
							<span class="lb-header">Repair Details</span>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group>
							<span class="lb-header">Quantity Control</span>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="6">
						<b-form-group label="Asset Type" label-class="font-weight-bold pt-0" label-cols-sm="4">
							{{ selAssetType ? selAssetType.name : '-' }}
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Asset Type" label-class="font-weight-bold pt-0" label-cols-sm="4">
							{{ selAssetType ? selAssetType.name : '-' }}
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="lb-total">
					<b-col sm="6">
						<b-form-group label="Total" label-class="font-weight-bold pt-0" label-cols-sm="4">
							{{ totalRepaired }}
						</b-form-group>
					</b-col>

					<b-col sm="6">
						<b-form-group label="Total" label-class="font-weight-bold pt-0" label-cols-sm="4">
							{{ totalRepaired }}
						</b-form-group>
					</b-col>
				</b-row>

				<b-row v-for="(classification, key) in classifications" :key="key" class="lb-condition">
					<b-col sm="6">
						<b-form-group :label="classification.condition" label-class="font-weight-bold pt-0"
							label-cols-sm="4">
							{{ classification.quantity }}
						</b-form-group>
					</b-col>

					<b-col sm="6">
						<b-form-group :label="classification.condition" label-class="font-weight-bold pt-0"
							label-cols-sm="4">
							{{ classification.quantity }}
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mt-1">
					<b-col sm="6">
						<b-form-group label="Date Time" label-for="DateTime">
							<date-range-picker name="DateTime" :min-date="minDate" :max-date="maxDate"
								:timePicker="true" :timePicker24Hour="false" opens="center" :single-date-picker="true"
								v-model="dateEndorsed" applyLabel="Apply" cancelLabel="Cancel"
								:style="{ width: '100%' }">
								<div slot="input">
									{{ formattedDate }}
								</div>
							</date-range-picker>
							<span v-show="errors.has('DateTime')" class="help-block">{{ errors.first('DateTime')
								}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Endorsed By" label-for="Endorsed By">
							<v-select name="Endorsed By" class="style-chooser" label="text" :options="userOptions"
								:reduce="(user) => user.value" v-model="selUser" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a user
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Endorsed By')" class="help-block">
								{{ errors.first('Endorsed By') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col sm="6">
						<b-form-group label="Supporting Document"
							description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
							<b-form-file id="supporting-docs" placeholder="Choose image" ref="supporting-docs"
								accept="image/png, image/jpeg, image/jpg" @change="onSelectDocument($event)">
								<template slot="file-name" slot-scope="{ names }">
									<b-badge variant="dark">{{ names[0] }}</b-badge>
									<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
										+ {{ names.length - 1 }} More files
									</b-badge>
								</template>
							</b-form-file>
						</b-form-group>
						<!-- Preview -->
						<div class="document-file" v-if="isNonNull(selDocument)">
							<i @click="onShowImage(selDocument.url)">{{ selDocument.name }}</i>
							<em @click="onRemoveDocument()" class="fa fa-times-circle"></em>
						</div>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Remarks" label-for="Remarks" description>
							<b-form-textarea name="Remarks" type="text" v-model="form.endorsed.remarks" maxlength="200"
								v-validate="getValidationParam(true, remarksRegex)" :rows="3" placeholder="Remarks" />
							<span v-show="errors.has('Remarks')" class="help-block">
								{{ errors.first('Remarks') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// Others
import config from '@/config/env-constants';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'endorse-repaired-batch',
	components: {
		Loading,
		DateRangePicker,
	},
	data() {
		return {
			form: { ...MaintenanceUtil.getBatchDefaultObj() },

			selAssetType: null,
			selUser: { ...config.userDefaultValue },
			selDocument: null,
			classifications: {},
			totalRepaired: 0,

			userOptions: [],
			allUsersObj: {},

			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Endorse Repaired Batch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		},

		dateEndorsed: {
			get() {
				return this.form.endorsed.dateEndorsed;
			},

			set(value) {
				this.form.endorsed.dateEndorsed = moment(value.startDate).valueOf();
			},
		},

		formattedDate() {
			return moment(this.dateEndorsed).format('MMMM D YYYY hh:mm A');
		},

		minDate() {
			const timestamp = DateUtil.getCurrentTimestamp();
			const adjustedTimestamp = DateUtil.getAdustedDateInDays(new Date(timestamp), 90);
			return new Date(adjustedTimestamp);
		},

		maxDate() {
			const timestamp = DateUtil.getCurrentTimestamp();
			return new Date(timestamp);
		},
	},
	mounted() {
		setTimeout(() => {
			this.allUsersObj = { ...this.$store.getters.users };
		}, config.timeout);

		EventBus.$on('onEndorseRepairedBatch', (value) => {

			this.isLoading = true;

			this.onReset();
			this.onInitData(value);

			this.isLoading = false;
		});
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			try {
				let isValid = await this.$validator.validateAll();
				if (!isValid) {
					this.$toaster.warning('Please address the field/s with invalid input');
					this.isLoading = false;
					return;
				}

				this.parseBatch();

				if (this.isValidBatch()) {
					this.$toaster.success(`Batch Repair number "${this.form.batchNo}" was endorsed successfully.`);
					EventBus.$emit('onCloseEndorsedBatch', this.form);
					this.$refs.modal.hide();
				}

			} catch (error) {
				this.$toaster.error(`Error saving batch repair. Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		parseBatch() {
			this.form.status = config.batchStatus.IN_WAREHOUSE.name;
			this.form.completed = true;

			this.form.endorsed.document = this.selDocument;
			this.form.endorsed.endorsedBy = this.selUser.id;

			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;
		},

		isValidBatch() {
			if (_.isEmpty(this.form.endorsed.document)) {
				this.$toaster.warning(`Endorsed batch has no supporting document.`);
				return false;
			}

			return true;
		},

		isNonNull(obj) {
			return obj && !_.isEmpty(obj);
		},

		onSelectDocument(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid license in PNG or JPEG format.'
				);
				vm.selDocument = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 2000 || dimensions.h > 2000) {
					vm.$toaster.warning(
						"The repaired photo width and height shouldn't be greater than 2000 pixels."
					);
				} else {
					vm.selDocument = {
						name: `${vm.form.maintenanceId}_${vm.form.batchNo}_endorsed_docs.jpg`,
						url: url,
						file: file,
						fbStoragePath: 'images/batches',
						isNew: true,
					};
				}
			};
			image.src = url;
		},

		onRemoveDocument() {
			this.selDocument = {};
		},

		onShowImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},

		onInitData(value) {
			this.form = value.repairedBatch;

			for (const item of this.form.classifications) {
				this.$set(this.classifications, item.condition, item);
				this.totalRepaired += item.quantity;
			}

			this.form.endorsed.dateEndorsed = DateUtil.getCurrentTimestamp();
			this.selDocument = this.form.endorsed.document;
			this.selAssetType = value.assetType;
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...MaintenanceUtil.getBatchDefaultObj() };

			this.selAssetType = null;
			this.selUser = { ...config.userDefaultValue };
			this.selDocument = null;
			this.classifications = {};
			this.totalRepaired = 0;

			this.userOptions = DropDownItemsUtil.retrieveActiveUsersByCompany(
				this.allUsersObj, this.loggedUserCompany.id
			);
		},
	},
	beforeDestroy() {
		EventBus.$off('onEndorseRepairedBatch');
		EventBus.$off('onCloseEndorseBatch');
	},
};
</script>

<style scoped>
.lb-header {
	font-weight: bold;
}

.document-file {
	font-weight: bold;
	margin-top: -0.5em;
	margin-bottom: 0.5em;
	cursor: pointer;
}

.document-file i {
	color: #008036;
	margin-right: 0.5em;
}

.lb-total {
	margin-top: -1em;
}

.lb-condition {
	margin-top: -1.5em;
}
</style>
